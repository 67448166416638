<template>
  <template v-if="isLoading">
    <div class="container">
      <img class="satellite-image" src="@/assets/images/satellite.png" />
      <p class="message">Waiting for {{ $store.state.app.dispatchBotName }} to be added to {{ waitingMessageEnding }}...</p>
      <button class="small secondary" @click="close">Cancel</button>
    </div>
  </template>
</template>

<script>

  import { mapGetters } from 'vuex'

  // @NOTE: Vue has a hard time with window references being passed as props or
  //  even in component data (it throws a bunch of weird errors when trying to
  //  make the object reactive?)
  //
  // I tried using the setup() method to have the passed prop non-reactive, as
  //  well as trying shallowRef(), both with no luck... so the only solution I
  //  can think of is just to store it as a (component-scoped) global variable
  //  here since the object doesn't actually need to be reactive
  let popupWindowRef = null

  export default {
    props: {
      pendingAccountIntegration: {
        type: Object,
        required: true,
      },
      popupUrl: {
        type: String,
        required: true,
      },
      onSuccess: {
        type: Function,
        required: true,
      },
      onError: {
        type: Function,
        required: true,
      },
      provider: {
        type: String,
        validator: (value) => {
          return value === 'telegram' || value === 'discord'
        },
      },
    },
    computed: {
      ...mapGetters('user', ['getAccountIntegrationById']),
      waitingMessageEnding() {
        switch (this.provider) {
          case 'discord': return 'a Discord server or direct message'
          case 'telegram': return 'a Telegram chat'
          default: return '' // do nothing
        }
      },
    },
    data() {
      return {
        isLoading: true,
        timeoutId: null,
        timeoutInterval: 2000,
      }
    },
    created() {

      window.addEventListener('message', this.onPostMessage)

      switch (this.provider) {

        case 'discord':
          this.openNewAccountIntegrationPopup(this.popupUrl)
          break

        case 'telegram':
          this.poll()
          this.openNewAccountIntegrationPopup(this.popupUrl)
          break

        default: // do nothing
      }

    },
    beforeUnmount() {
      this.stopPolling()
      window.removeEventListener('message', this.onPostMessage)
    },
    methods: {
      close() {
        this.$store.dispatch('modals/CLOSE_MODAL')
        if (popupWindowRef && !popupWindowRef.closed) popupWindowRef.close()
      },
      onPostMessage(event) {

        if (!event.data) return
        if (event.source !== popupWindowRef) return
        if (event.origin !== window.location.origin) return

        const { status, newAccountIntegrationId } = event.data

        // this should never NOT be true, but just in case they have two windows
        //  open, both going through the auth flow...
        if (this.pendingAccountIntegration.id !== newAccountIntegrationId) return

        if (status === 'success') {
          this.stopPolling()
          this.poll()

        } else {
          this.onError(this.pendingAccountIntegration)
          this.close()
        }

      },
      poll() {
        this.$store.state.api.dispatch
          .get(`/integrations/${this.pendingAccountIntegration.id}`)
          .then((response) => {

            const updatedAccountIntegration = response.data

            if (updatedAccountIntegration.isVerified !== true && !updatedAccountIntegration.duplicateId) {
              this.timeoutId = setTimeout(this.poll, this.timeoutInterval)
              return
            }

            this.stopPolling()

            if (updatedAccountIntegration.duplicateId) {

              const duplicatedAccountIntegration = this.getAccountIntegrationById(updatedAccountIntegration.duplicateId)

              if (!duplicatedAccountIntegration) {
                throw new Error(`Could not find duplicate account integration with id ${updatedAccountIntegration.duplicateId}.`)
              }

              this.onSuccess(duplicatedAccountIntegration)

            } else {
              this.onSuccess(updatedAccountIntegration)
            }

            this.close()

          })
          .catch((error) => {
            this.onError(this.pendingAccountIntegration)
          })
      },
      stopPolling() {
        clearInterval(this.timeoutId)
      },
      openNewAccountIntegrationPopup(url) {
        const width = 980
        const height = 700
        const x = (window.outerWidth / 2) + window.screenX - (width / 2)
        const y = (window.outerHeight / 2) + window.screenY - (height / 2)
        popupWindowRef = window.open(url, 'new-account-integration-popup', `popup, innerWidth=${width}, innerHeight=${height}, top=${y}, left=${x}`)
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .container
    @apply flex
    @apply flex-col
    @apply items-center
    @apply justify-center

  .message
    @apply mb-8
    @apply text-xl
    @apply text-center
    @apply font-semibold

  .satellite-image
    @apply h-32
    @apply mb-8

</style>
